import React, { useEffect, useState } from 'react';
import './HeroCarousel.css';

const getImagesFromFolder = (count) => {
  return Array.from({ length: count }, (_, index) => `image${index + 1}.png`).sort(() => Math.random() - 0.5);
};

const HeroCarousel = () => {
  const [imagesTop, setImagesTop] = useState([]);
  const [imagesMiddle, setImagesMiddle] = useState([]);
  const [imagesBottom, setImagesBottom] = useState([]);
  const [highlightedImages, setHighlightedImages] = useState({
    top: [],
    middle: [],
    bottom: [],
  });
  const [isAbsolute, setIsAbsolute] = useState(true);

  useEffect(() => {
    // Generate separate random images for each row
    setImagesTop(getImagesFromFolder(54));
    setImagesMiddle(getImagesFromFolder(54));
    setImagesBottom(getImagesFromFolder(54));

    // Initialize the highlighted state for each image to false
    setHighlightedImages({
      top: Array(54).fill(false),
      middle: Array(54).fill(false),
      bottom: Array(54).fill(false),
    });
  }, []);

  useEffect(() => {
    const highlightInterval = setInterval(() => {
      setHighlightedImages((prevHighlightedImages) => {
        const newHighlightedImages = { ...prevHighlightedImages };

        // Highlight each row independently
        ['top', 'middle', 'bottom'].forEach((row) => {
          newHighlightedImages[row] = newHighlightedImages[row].map((isHighlighted) => {
            // Reset highlight if it was previously highlighted
            if (isHighlighted) {
              return false; // Turn off highlighting after it has been highlighted
            } else {
              // Randomly decide to highlight the image
              return Math.random() < 1 / 6; // Adjust this probability as needed
            }
          });
        });

        return newHighlightedImages;
      });
    }, 3000);

    return () => clearInterval(highlightInterval);
  }, []);

  useEffect(() => {
    const positionInterval = setInterval(() => {
      setIsAbsolute((prevIsAbsolute) => !prevIsAbsolute);
    }, 6000);

    return () => clearInterval(positionInterval);
  }, []);

  return (
    <div className={`carousel-container ${isAbsolute ? 'absolute-position' : 'relative-position'}`}>
      {/* Overlay text */}
      <div className="overlay-text">XR Innov</div>

      <div className="carousel-layer-container top-carousel">
        {imagesTop.map((image, index) => (
          <div
            key={`top-${index}`}
            className={`carousel-slide ${highlightedImages.top[index] ? 'highlighted' : ''}`}
            style={{
              backgroundImage: `url(/images/hero/${image})`,
            }}
          ></div>
        ))}
      </div>
      <div className="carousel-layer-container middle-carousel">
        {imagesMiddle.map((image, index) => (
          <div
            key={`middle-${index}`}
            className={`carousel-slide ${highlightedImages.middle[index] ? 'highlighted' : ''}`}
            style={{
              backgroundImage: `url(/images/hero/${image})`,
            }}
          ></div>
        ))}
      </div>
      <div className="carousel-layer-container bottom-carousel">
        {imagesBottom.map((image, index) => (
          <div
            key={`bottom-${index}`}
            className={`carousel-slide ${highlightedImages.bottom[index] ? 'highlighted' : ''}`}
            style={{
              backgroundImage: `url(/images/hero/${image})`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default HeroCarousel;
