import React from 'react';
import './AboutPage.css';
import { useTranslation } from 'react-i18next';

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <div className="about-page">
      d
      <h1>{t('about.title')}</h1>
      <p>{t('about.description')}</p>
      <div className="about-team">
        <h2>{t('about.teamTitle')}</h2>
        <p>{t('about.teamDescription')}</p>
      </div>
    </div>
  );
};

export default AboutPage;