import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
        <li><Link className="footer-bottom">{t('footer.about')}</Link></li>
          <p>{t('footer.aboutDescription')}</p>
        </div>
        <div className="footer-section">
        <li><Link to="/contact" className="footer-bottom">{t('footer.contact')}</Link></li>
          <p>{t('footer.contactDescription')}</p>
        </div>
        <div className="footer-section">
          <li><Link className="footer-bottom">{t('footer.followUs')}</Link></li>
          <ul className="social-links">
            <li><a href="https://www.linkedin.com/company/xr-innov/.com" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} XR Innov. {t('footer.rights')}
          <i className='footer-space'>-------------------------</i>
          <i className='footer-ai'> {t('footer.ai')}</i>
        </p>
      </div>
    </footer>
  );
};

export default Footer;


                