import en from './en.json';
import fr from './fr.json';

const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
};

export default resources;
