import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css';

const Navbar = ({ changeLanguage }) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="logo">
                <img src="/images/logBlanc.png" alt="XR innov logo" className="logo-img" />
            </div>
            
            <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
                <span className="hamburger"></span>
            </button>

            <div className={`nav-content ${isMenuOpen ? 'active' : ''}`}>
                <ul className="nav-links">
                    <li><Link to="/" className="nav-button" onClick={() => setIsMenuOpen(false)}>{t('navbar.home')}</Link></li>
                    <li><Link to="/contact" className="nav-button" onClick={() => setIsMenuOpen(false)}>{t('navbar.contact')}</Link></li>
                </ul>
                
                <div className="language-switcher">
                    <button className="language-button" onClick={() => changeLanguage('en')}>EN</button>
                    {" | "}
                    <button className="language-button" onClick={() => changeLanguage('fr')}>FR</button>
                </div>
            </div>
            
            <div hidden>
                <input name="bot-field" />
            </div>
        </nav>
    );
};

export default Navbar;