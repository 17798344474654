import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from './i18n';
import './App.css';
import Navbar from './Navbar';
import AppRoutes from './Routes';
import Footer from './Footer';

function App() {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Router>
      <div className="App">
        <Navbar changeLanguage={changeLanguage} />
        <AppRoutes />
        <Footer />
      </div>
    </Router>
  );
}

export default App;