import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import './ContactPage.css';
import { useTranslation } from 'react-i18next';

const ContactPage = () => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_f4hqw1f',            // Service ID
        'template_z41c0ln',           // Template ID
        form.current,                 // Form reference
        '72G_5OxcpY_G7lDB0'           // Public key (User ID)
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact-page">
      <h1>{t('contact.title')}</h1>
      {!submitted ? (
        <form ref={form} className="contact-form" onSubmit={sendEmail}>
          <div>
            <label htmlFor="name">{t('contact.name')}:</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div>
            <label htmlFor="email">{t('contact.email')}:</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div>
            <label htmlFor="message">{t('contact.message')}:</label>
            <textarea id="message" name="message" required></textarea>
          </div>
          <button type="submit">{t('contact.submit')}</button>
        </form>
      ) : (
        <div className="thank-you-message">
          <h3>{t('contact.thankYou')}</h3>
          <p>{t('contact.thankYouDetail')}</p>
        </div>
      )}
      <div className="contact-info">
        <p><strong>{t('contact.phoneLabel')}:</strong> +33 6 99 85 89 46</p>
        <p><strong>{t('contact.emailLabel')}:</strong> contact@xrinnov.com</p>
      </div>
    </div>
  );
};

export default ContactPage;
