import React from 'react';
import './ServiceItem.css';
import { useInView } from 'react-intersection-observer';

const ServiceItem = ({ title, description, image }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1, // Element is considered in view when 10% of it is visible
  });

  return (
    <div ref={ref} className={`service-item ${inView ? 'show' : ''}`}>
      <div
        className="service-background"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default ServiceItem;
