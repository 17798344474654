import React from 'react';
import './HomePage.css';
import { useTranslation } from 'react-i18next';
import ServiceItem from './ServiceItem';
import HeroCarousel from './HeroCarousel';

const HomePage = () => {
  const { t } = useTranslation();
  const services = t('services.items', { returnObjects: true });

  return (
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero">
        <HeroCarousel />
      </section>      


      {/* Services Section */}
      <section id="services" className="services-section">
        
      <div className="service-overlay">
        <div className="rotating-cube">
          <div className="cube-face cube-face-front"></div>
          <div className="cube-face cube-face-back"></div>
          <div className="cube-face cube-face-left"></div>
          <div className="cube-face cube-face-right"></div>
          <div className="cube-face cube-face-top"></div>
          <div className="cube-face cube-face-bottom"></div>
        </div>
      </div>
        <h2>{t('services.title')}</h2>
        
        <br/>
        <div className="services-grid">
          {services.map((service, index) => (
            <ServiceItem
              key={index}
              title={service.title}
              description={service.description}
              image={`/images/service${index + 1}.png`}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomePage;